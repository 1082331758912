import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {PublicTableDTO} from "../home/types";
import {Button, Card, Divider, Form, Image, Input, InputNumber, Modal, Progress, Result, Typography} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import {useForm} from "antd/lib/form/Form";
import {defaultFormItemLayout} from "../commons/forms";
import {ReservationRequest} from "./types";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";

export type TableCardProps = {
    table: PublicTableDTO;
}

export const TableCard: React.FC<TableCardProps> = (props) => {
    const {table} = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const [reserved, setReserved] = useState<boolean>(false);
    const [file, setFile] = useState<string | undefined>(undefined);
    const toggleExpand = useCallback(() => {setExpanded(!expanded)}, [expanded, setExpanded]);
    const canAcceptReservations = useMemo<boolean>(() => table.reserved < table.seatsMax, [table.reserved, table.seatsMax])
    const maxSeats = useMemo<number>(() => table.seatsMax - table.reserved, [table.reserved, table.seatsMax])
    const [form] = useForm<ReservationRequest>();

    useEffect(() => {
        if (!form.getFieldsValue().seats) {
            form.setFieldsValue({
                ...form.getFieldsValue,
                seats: 1
            });
        }
    }, [form]);

    const reserve = useCallback(() => {
        axios.post(`/api/reservations/${table.id}`, form.getFieldsValue(), {
            responseType: "blob"
        })
            .then(r => {
                const fileName = r.headers['x-reservation-file-id'] || `rpg-lodge-reservation-${table.title}.pdf`;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(r.data);
                link.download=fileName;
                link.click();
                setReserved(true);
                setFile(fileName);
            })
            .catch(e => {
                const messsage = e?.response?.status === 400
                    ? e?.message || 'Something went wrong, please try again later or contact RPG Lodge directly!'
                    : 'Something went wrong, please try again later or contact RPG Lodge directly!';
                Modal.error({
                    title: 'We couldn\'t reserve your seat just now.',
                    content: messsage
                });
            })
    }, [form, table.id]);
    const progress = useMemo<number>(() => Math.floor((table.reserved/table.seatsMax) * 100), [table.reserved, table.seatsMax]);

    return (
        <Card title={table.game} style={{margin: '3px'}} key={table.id}>
            {!reserved &&
                <>
                    <Typography.Text strong>{table.title}</Typography.Text>
                    <br/>
                    {!!table.mediaUrl &&
                        <>
                            <Image src={table.mediaUrl} alt={'session media'} style={{height: '20em'}} />
                            <br/>
                        </>
                    }
                    <Typography.Text italic >{`Hosted by ${table.facilitator}`}</Typography.Text>
                    <br/>
                    <Paragraph
                        ellipsis={expanded ? false : {
                            rows: 1,
                        }}
                    >
                        {table.description}
                    </Paragraph>
                    <Button type={"link"} onClick={toggleExpand}>{expanded ? 'Show less' : 'Learn more'}</Button>
                    <Divider type={"horizontal"} />
                    <div>
                        <Progress percent={progress} steps={table.seatsMax} showInfo={false} />
                        <br/>
                        {`${table.reserved} / ${table.seatsMax} players reserved`}
                    </div>
                    <br/>
                    {!canAcceptReservations && <Typography.Text strong>The table is full. Please consider joining another table.</Typography.Text>}
                    <Form form={form} {...defaultFormItemLayout} onFinish={reserve}>
                        <Form.Item name="name" rules={[{ required: true }]} >
                            <Input  placeholder="Your name" disabled={!canAcceptReservations || reserved} required />
                        </Form.Item>
                        <Form.Item name="seats" rules={[{ required: true }]} >
                            <InputNumber min={1} max={maxSeats} disabled={!canAcceptReservations || reserved} required />
                        </Form.Item>
                        <Form.Item name="contactInfo">
                            <TextArea placeholder="Contact Information (please help us ensure everyone is on time). Enter your phone number or any social media you would prefer us to contact you at."
                                      disabled={!canAcceptReservations || reserved}
                                      required={false}
                                      maxLength={100}
                                      showCount={true}
                            />
                        </Form.Item>
                        <Button type={"primary"} onClick={form.submit} disabled={!canAcceptReservations || reserved} >Reserve</Button>
                    </Form>
                </>
            }
            {reserved &&
                <Result title={'Thank you for the reservation'} status={"success"}>
                    <p>Please save the PDF you received and bring it with you.</p>
                    <b>Your reservation file's name is {file}</b>
                    <br/>
                    <a href={`/api/reservations/${file}`} download={file}>You can download your PDF reservation here if it didn't download automatically.</a>
                </Result>
            }
        </Card>
    );
}