import React, {useCallback} from 'react';
import {Card, Image, Result, Spin, Typography} from "antd";
import {CommonLayout} from "../commons/CommonLayout";
import {useQuery} from "react-query";
import axios, {AxiosResponse} from "axios";
import {PublicEventDTO} from "./types";
import './home.css'
import {DateBox} from "./DateBox";
import {useNavigate} from "react-router";

export const Home = () => {
    const navigate = useNavigate();
    const {isLoading, isError, data: upcoming} = useQuery(`upcoming-events`,
        () => axios.get<any,AxiosResponse<PublicEventDTO[]>>(`/api/events` ).then(r => r.data));

    const plurallize = (a: number, b: string) => {
        return a > 1
            ? `${a} ${b}s`
            : `${a} ${b}`;
    }

    const selectEvent = useCallback((event: PublicEventDTO) => {
        navigate(`/events/${event.id}`);
    }, [navigate]);


    if (isError) {
        return (<Result status={"error"} />);
    }

    return (
        <CommonLayout>
            <Typography.Title level={5}>Welcome to the lodge. If there are any upcoming events on the lodge you can find them below.</Typography.Title>
            {isLoading && <Result status={"info"}><Spin /></Result>}
            {!!upcoming &&
                upcoming.map((event: PublicEventDTO) => (
                    <Card key={event.id}  onClick={() => selectEvent(event)} className="card-public-event">
                        <div style={{display: 'flex'}}>
                            <DateBox date={event.startsAt} />
                            <div style={{height: '85px', flexGrow: '1', marginLeft: '20px'}}>
                                <Typography.Text strong >{event.title}</Typography.Text>
                                <br/>
                                <Typography.Text>{event.location}</Typography.Text>
                                <br/>
                                <Typography.Text italic>{`${plurallize(event.tables.length, 'table')}!`}</Typography.Text>
                            </div>
                        </div>
                        {!!event.mediaUrl && <><br/><Image src={event.mediaUrl} style={{height: '20em'}} alt={'event media'}/></>}
                    </Card>
                ))
            }
        </CommonLayout>
    )
};