import React, {PropsWithChildren} from 'react';
import {ConfigProvider, Row} from "antd";
import localeValues from "antd/es/locale/el_GR";

export const CommonLayout: React.FC<PropsWithChildren> = (props) => {
    return (
        <ConfigProvider theme={{
            token: {
                fontFamily: "Gill Sans, sans-serif"
            }
        }} locale={localeValues}>
            <div style={{height: '10vh'}}>
                <Row style={{paddingLeft: '2.5vh', paddingTop: '1.5vh', height: '90%'}} >
                    <img src={'/lodge-192.png'} height={'40px'} style={{marginTop: '1em'}} alt={'logo'}  />
                    <h1 style={{marginLeft: '1em', height: '90%'}} >RPG Lodge</h1>
                </Row>
            </div>
            <div style={{height: '89.5vh', overflowY: 'scroll', backgroundColor: '#edede9', padding: '0.5em'}}>
                {props.children}
            </div>
        </ConfigProvider>
    )
}