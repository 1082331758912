import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Home} from "./home/Home";
import LoginPage from "./admin/LoginPage";
import {Administration} from "./admin/Administration";
import {TablesEditor} from "./tables/TablesEditor";
import {Reservation} from "./reservation/Reservation";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();
const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        hasErrorBoundary: true,
        errorElement: (<div>404</div>),
        index: true
    },{
        path: "/events/:event",
        element: <Reservation />,
    }, {
        path: "/admin",
        element: <Administration />
    }, {
        path: "/admin/tables/:event",
        element: <TablesEditor />
    }, {
        path: "/admin/login",
        element: <LoginPage />
    }
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
