import React, {useCallback} from 'react';
import {Button, Card, Form, Layout, message, Typography} from "antd";
import Password from "antd/es/input/Password";
import {useForm} from "antd/lib/form/Form";
import axios from "axios";
import {useNavigate} from "react-router";
import {startAuth} from './auth';

const LoginPage = () => {
    const [form] = useForm<{password: string|undefined}>();
    const navigate = useNavigate();

    const doLogin = useCallback(() => {
        const token = btoa(`admin:${form.getFieldsValue().password}`);
        axios.get(`/api/admin/login`, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
        .then(() => {
            startAuth(token);
            message.success('Welcome to the Lodge!').then();
            navigate('/admin');
        })
        .catch((e) => {
            message.error('Wrong Password, please try again!').then();
        })

    }, [form, navigate]);

    return (
        <>
            <Layout style={{height:'100vh'}}>
                <div style={{
                    margin: 'auto',
                    width: '50%'
                }}>
                    <Card>
                        <Typography.Text>Please enter the administration password.</Typography.Text>
                        <Form form={form} onFinish={doLogin} >
                            <Form.Item name="password">
                                <Password />
                            </Form.Item>
                            <div style={{width: '100%'}}>
                                <Button type={"default"} style={{float: 'right'}} onClick={form.submit}>
                                    <Typography.Text>Login</Typography.Text>
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default LoginPage;